import _reactSocialMediaIcons from "./react-social-media-icons";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ReactSocialMediaIcons", {
  enumerable: true,
  get: function get() {
    return _ReactSocialMediaIcons.default;
  }
});

var _ReactSocialMediaIcons = _interopRequireDefault(_reactSocialMediaIcons);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

export default exports;
export const __esModule = exports.__esModule,
      ReactSocialMediaIcons = exports.ReactSocialMediaIcons;